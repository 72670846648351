<template>
    <div>
        <div class="gallery-container">

            <h3>I don't like Instagram and VSCO is shit now so</h3>
            <h1>These are some photos</h1>
            
            <div class="photo-container">

                <img src="/photos/IMG_1976.jpg">
                <img src="/photos/IMG_1837.png">
                <img src="/photos/DSCF0487.jpg">
                <img src="/photos/DSCF0485.jpg">
                <img src="/photos/DSCF0478.jpg">


                <!-- Spain 2024 special photo -->
                <div class="special-photo-container">
                    <img
                        src="/photos/Spain2024/DSCF0323.jpg"
                        class="photo special-photo"
                        @click="showModal = 'spain'"
                    />
                    <span class="overlay-text">Spain 2024</span>
                </div>
                <!-- UK 2024 special photo -->
                <div class="special-photo-container">
                    <img
                        src="/photos/Uk2024/DSCF0140.jpg"
                        class="photo special-photo"
                        @click="showModal = 'uk'"
                    />
                    <span class="overlay-text">UK 2024</span>
                </div>

                <img src="/photos/IMG_2066.png">
                <img src="/photos/IMG_1123.jpg">
                <img src="/photos/DSCF0104.jpg">
                <img src="/photos/DSCF0112.jpg">
                <img src="/photos/DSCF0043.jpg">
                <img src="/photos/DSCF0035.jpg">
                <img src="/photos/DSCF0048.jpg">
                <img src="/photos/guitar.jpg">
                <img src="/photos/unnamed.jpg">
                <img src="/photos/000234240005 (1).jpg">
                <img src="/photos/000234240025.jpg">
                <img src="/photos/000234240008.jpg">
                <img src="/photos/IMG_0245.jpg">
                <img src="/photos/IMG_9946.jpg">
                <img src="/photos/IMG_9738.jpg">
                <img src="/photos/IMG_9633.jpg">
                <img src="/photos/IMG_9336.jpg">
                <img src="/photos/IMG20231123105744.jpg">
                <img src="/photos/IMG_9083.jpg">
                <img src="/photos/IMG_8711.jpg">
                <img src="/photos/IMG_8473.jpg">
                <img src="/photos/IMG_8304.jpg">
                <img src="/photos/IMG_7794.jpg">
                <img src="/photos/IMG_7041.jpg">
                <img src="/photos/IMG_6807.jpg">
                <img src="/photos/IMG_6587.jpg">
                <img src="/photos/IMG_6576.jpg">
                <img src="/photos/IMG_6539.jpg">
                <img src="/photos/IMG_7330.jpg">
                <img src="/photos/IMG_7197.jpg">
                <img src="/photos/IMG_7321.jpg">
                <img src="/photos/IMG_7301-VSCO.jpg">
                <img src="/photos/IMG_7344.png">
                <img src="/photos/IMG_6906.jpg">
                <img src="/photos/IMG_5710.jpg">
                <img src="/photos/IMG_5566.jpg">
                <img src="/photos/IMG_7670.jpg">
                <img src="/photos/IMG_4535.jpg">
                <img src="/photos/IMG_6152-VSCO.jpg">
                <img src="/photos/IMG_5317.jpg">
                <img src="/photos/IMG_0477.jpg">
                <img src="/photos/IMG_20191211_115235-VSCO.jpg">
                <img src="/photos/IMG20191205145500.jpg">
                <img src="/photos/P1050988-VSCO.jpg">
                <img src="/photos/IMG20191205173330.jpg">
                <img src="/photos/P1060005.jpg">
                <img src="/photos/IMG_20191206_113250.jpg">
                <img src="/photos/IMG20191206130737.jpg">
                <img src="/photos/IMG20191204112835.jpg">
                <img src="/photos/IMG20191203113145-VSCO.jpg">
                <img src="/photos/IMG20191116192705-VSCO.jpg">
                <img src="/photos/969-VSCO.jpg">
                <img src="/photos/CSHR.jpg">
                <img src="/photos/received_490326714679845.jpeg">
                <img src="/photos/P1040058.jpg">
                <img src="/photos/RC29MNY.jpg">
                <img src="/photos/3906-VSCO.jpg">
                <img src="/photos/IMG_0185-VSCO.jpg">
                <img src="/photos/IMG_1962-VSCO.jpg">
                <img src="/photos/IMG_8575-VSCO.jpg">
            </div>
        </div>
      <!--  <address>
         <button @click="goHome">For business enquiries</button>
      </address>  -->
        <!-- Popup overlay for the UK 2024 group -->
        <div v-if="showModal === 'uk'" class="modal-overlay">
            <div class="modal-content">
                <button @click="showModal = null" class="close-button">Close</button>
                <div class="expanded-photos">
                <img
                    v-for="(photo, index) in ukPhotos"
                    :key="index"
                    :src="photo"
                    class="additional-photo"
                />
                </div>
            </div>
            </div>

            <!-- Popup overlay for the Spain 2024 group -->
            <div v-if="showModal === 'spain'" class="modal-overlay">
            <div class="modal-content">
                <button @click="showModal = null" class="close-button">Close</button>
                <div class="expanded-photos">
                <img
                    v-for="(photo, index) in spainPhotos"
                    :key="index"
                    :src="photo"
                    class="additional-photo"
                />
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
    export default {
    data() {
        return {
        // Flag to track if the special group is expanded
        showModal: false,
        // Photos that will be revealed when clicking the special photo
        specialGroupPhotos: [
            "/photos/Uk2024/DSCF0140.jpg",
            "/photos/Uk2024/DSCF0142.jpg",
            "/photos/Uk2024/DSCF0161.jpg",
            "/photos/Uk2024/DSCF0199.jpg",
            "/photos/Uk2024/DSCF0206.jpg",
            "/photos/Uk2024/DSCF0209.jpg",
            "/photos/Uk2024/DSCF0217.jpg",
            "/photos/Uk2024/DSCF0219.jpg",
            "/photos/Uk2024/DSCF0226.jpg",
            "/photos/Uk2024/DSCF0231.jpg",
            "/photos/Uk2024/DSCF0248.jpg",
            "/photos/Uk2024/DSCF0259.jpg",
            "/photos/Uk2024/DSCF0272.jpg",
            "/photos/Uk2024/DSCF0273.jpg",
            "/photos/Uk2024/DSCF0292.jpg"
        ],
        // Photos for the Spain group
        spainPhotos: [
            "/photos/Spain2024/DSCF0323.jpg",
            "/photos/Spain2024/IMG_1616.jpg",
            "/photos/Spain2024/DSCF0330.jpg",
            "/photos/Spain2024/DSCF0334.jpg",
            "/photos/Spain2024/DSCF0342.jpg",
            "/photos/Spain2024/DSCF0357.jpg",
            "/photos/Spain2024/DSCF0358.jpg",
            "/photos/Spain2024/DSCF0370.jpg",
            "/photos/Spain2024/DSCF0374.jpg",
            "/photos/Spain2024/DSCF0375.jpg",
            "/photos/Spain2024/DSCF0388.jpg",
            "/photos/Spain2024/DSCF0399.jpg",
            "/photos/Spain2024/DSCF0404.jpg",
            "/photos/Spain2024/DSCF0423.jpg",
            "/photos/Spain2024/DSCF0425.jpg",
            "/photos/Spain2024/DSCF0431.jpg",
            "/photos/Spain2024/DSCF0434.jpg",
            "/photos/Spain2024/DSCF0452.jpg",

        ],
        };
    },

    };
    </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .gallery-container {
    position: fixed;
    top: 0%;
    left: 5%;
    right: 5%;
    bottom: 7%;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .gallery-container::-webkit-scrollbar {
    display: none;
  }
  
  h1 {
    text-decoration: underline;
    margin-top: -1%;
    margin-bottom: 2.5%;
  }
  
  h3 {
    font-weight: 500;
    font-size: medium;
    margin-top: 2.5%;
  }
  
  .photo-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
  }
  
  .photo-container img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .photo {
    cursor: pointer;
  }

  .special-photo-container {
  position: relative;
  display: inline-block;
    }
  
  .special-photo {
  border-right: 7px solid grey;
  border-bottom: 7px solid grey;
  padding: 0px;
  }   

  .overlay-text {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px; /* Adjust font size as needed */
  pointer-events: none; /* Ensure the text doesn’t interfere with the click event */
    }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .close-button {
    background: #ff6600;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .expanded-photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* two columns */
    gap: 10px;
  }
  
  .additional-photo {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
   /* Change to one column on small screens for main and popups */
  @media (max-width: 600px) {
    .photo-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 600px) {
  .expanded-photos {
    grid-template-columns: repeat(1, 1fr); 
  }
}
  </style>
  